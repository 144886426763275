@import "/assets/scss/base/variables";

$sidebarWidth: 350px;
$sidebarHandleWidth: 25px;

@keyframes opacityPulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

html,
body {
  height: 100%;
  overflow: hidden;
}

#root,
.rows,
.reveal,
.reveal>*,
.fullscreen {
  width: 100%;
  height: 100%;
}

.clickable {
  cursor: pointer;
}

@keyframes heightOut {
  0% {
    margin-top: 0;
  }

  100% {
    margin-top: -100vh;
  }
}

.transition-height-out {
  animation-name: heightOut;
  transition-property: margin-top;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Barlow', sans-serif;
  font-weight: 600;

  #loader {
    width: 100%;
    height: 100%;
    background: $color-secondary;
    display: flex;
    //@extend .flex-transition;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    transition: height 1s;

    img {
      height: auto;
      width: 15em;
      filter: brightness(0) invert(1);
      animation: opacityPulse 1s linear infinite;
    }

    &:not(.active) {
      height: 0%;
    }
  }

  .rows {
    display: flex;
    flex-direction: column;

    #header,
    #footer {
      width: 100%;
      height: 4em;
      padding: .5em 0;
      background-color: $color-white;
      color: $color-text;
      position: relative;
    }

    #header {
      position: relative;
      display: flex;

      >* {
        flex: 1;
      }

      div#header-logo {
        flex-basis: $sidebarWidth;
        flex-grow: 0;

        img {
          height: 80%;
          margin-left: 1.2em;
        }
      }

      div#menu {
        display: flex;
        align-items: center;

        button {
          background: $color-secondary;
          color: $color-primary;
          margin-right: 2em;

          &#filters-bt {
            &:not(.active) {
              pointer-events: none;
              opacity: .5;
              transition: opacity 1;
            }
          }
        }

      }

    }

    #main {
      position: relative;

      #sidebar {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        flex-basis:calc(#{$sidebarWidth} + #{$sidebarHandleWidth});
        transition: margin-left .5s;
        color: $color-text;
        background-color: $color-white;

        >* {
          flex: 1;
        }

        #sidebar-content {
          overflow: auto;
          display: flex;
          flex-direction: column;

          #menu-help {
            padding: 1em;

            ul {
              padding: 0;
              margin: 0;
              font-size: .8em;
              list-style: none;
            }
          }

          #copyright {
            font-size: .8em;
            margin-top: auto;
            padding-top: 2em;
            padding: 1em;
          }
        }

        #sidebar-logo {
          // flex-basis: $sidebarWidth;
          flex-grow: 0;

          img {
            height: 80%;
            margin-left: 1.2em;
          }
        }

        #sidebar-handle-bar {
          background: $color-secondary;
          flex-basis: $sidebarHandleWidth;
          flex-grow: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }

        .container-buttons {
          margin: 10px;

          button {
            background: $color-secondary;
            color: $color-primary;
            width: 100%;
            font-family: 'Barlow', sans-serif;
            text-transform: uppercase;

            &:not(:last-child) {
              margin-bottom: 10px;
            }

            &#filters-bt {
              &:not(.active) {
                pointer-events: none;
                opacity: .5;
                transition: opacity 1;
              }
            }
          }
        }

        &:not(.active) {
          margin-left: -$sidebarWidth;
        }
      }
    }
  }

  .columns {
    // height: calc(100% - 6em); // - header - footer
    height: 100%;
    display: flex;

    #sidebar {
      display: flex;
      flex-direction: column;
    }

    #map {
      flex-grow: 1;
    }
  }

  #footer {
    ul#captions {
      text-align: right;
      width: 100%;
      line-height: 2em;

      li {
        display: inline;
        padding: 0 1em;

        .box {
          width: 1em;
          height: 1em;
          display: inline-block;
          margin-right: .5em;
        }
      }

      margin-right:2em;
    }
  }

  ::-webkit-scrollbar-track {
    background: #9C9C9C;
  }

  ::-webkit-scrollbar-thumb {
    background: $color-secondary;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $color-black;
  }
}

body {
  font-family: 'Barlow', sans-serif;
}

h3 {
  color: #BF616A;
}

ul {
  margin-left: 1em;
}

.togglable {
  color: #D78770;
  cursor: pointer;
}

.togglable-down::after,
.togglable-up::after {
  font-size: 8px;
  margin-left: 0.5em;
}

.togglable-down::after {
  content: "▼";
  display: inline-block;
}

.togglable-up::after {
  content: "▶";
  display: inline-block;
}