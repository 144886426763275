@import "/assets/scss/base/variables";

.treeNode {
  text-transform: uppercase;

  ul {
    margin: 0;
    padding: 0;

    >li {
      .label {
        position: relative;
        padding: 0 1em;
        height: 3em;
        display: flex;
        align-items: center;

        span {
          flex: 1;

          &.name {
            // line-height:3em;
          }

          &.item-toggle {
            flex-grow: 0;
          }
        }
      }

      ul.districts {
        >li {
          display: flex;
          align-items: center;

          >span {
            flex: 1;

            &.district-name {
              padding: 0 1em;
              line-height: 3em;
              height: 3em;

            }

          }

        }
      }
    }
  }

  >.label {
    .chevron {
      transition: transform .25s;
    }
  }

  .item-toggle {
    text-align: right;
    padding-right: 1em;

    .ui.toggle.checkbox {
      border: 0; //fix bug in semantic UI ?
      transform: scale(.75);

      /*default NOT CHECKED color*/
      input~.box:before,
      input~label:before {
        background-color: $color-secondary !important;
        transition: background-color 500ms linear;
        border: 1px solid #FFF;
      }

      /*default CHECKED color*/
      input:checked~.box:before,
      input:checked~label:before {
        background-color: $color-primary !important;
        /*default CHECKED color*/
      }

      & input~.box:after,
      & input~label:after {
        box-shadow: none;
        width: 1.15rem;
        height: 1.15rem;
        background-color: #E0F6FB;
        top: 0.125rem;
        left: 0.15rem;
      }

      & input:checked~.box:after,
      & input:checked~label:after {
        left: 2.15rem;
      }
    }
  }

  &:not(.open) {
    >.label {
      .chevron {
        transform: rotate(180deg);
      }
    }

    ul {
      display: none;
    }
  }

  &:not(.hasChildren)>.label,
  &:not(.hasChevron)>.label {
    .chevron {
      visibility: hidden;
    }
  }

  &.checked {
    .item-toggle {
      text-align: right;
      padding-right: 1em;

      .ui.toggle.checkbox {

        /*temporary fix*/
        &[data-togglecolor="#acacac"] {
          input:checked~label:before {
            background-color: #acacac !important;
          }
        }

        &[data-togglecolor="#28b1cc"] {
          input:checked~label:before {
            background-color: #28b1cc !important;
          }
        }

        &[data-togglecolor="#b3bd00"] {
          input:checked~label:before {
            background-color: #b3bd00 !important;
          }
        }
      }
    }
  }

  &.level-0 {
    >.label {
      display: none;
    }
  }

  &.level-1 {
    // border:1px solid $color-secondary;
    // padding: 5px;
    margin: 10px;


    >.label {
      background-color: $color-white;
      border-left: none;
      border-right: none;
      border: 1px solid $color-secondary;
      border-radius: 10px;
      color: $color-primary;

      & i {
        color: $color-text;
      }
    }

    ul {
      overflow: hidden;
      border: 1px solid $color-secondary;
      border-radius: 10px;
      margin-top: 10px;
    }

    ul li {

      background: #FFF;
      color: #494747;

      &:hover {
        background: #F2F2F2;
        cursor: pointer;
      }
    }

    &.open {
      >.label {
        background-color: $color-secondary;
        color: $color-primary;

        & i {
          color: $color-primary;
        }
      }
    }
  }

  &.level-2 {
    ul {
      border: none;
      border-radius: unset;
    }

    &>.label .name {
      // padding-left: 1em;
    }
  }

  &.level-3>.label .name {
    padding-left: 2em;
  }

  &.level-4>.label .name {
    padding-left: 3em;
  }
}